<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">書單下載</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <!-- <div class="xl:flex sm:mr-auto">
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="save"
            >
              儲存
            </button>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <div class="p-2 border">
              <div class="text-right p-2">
                <!-- <div>MII DM上傳</div> -->
                <file-upload
                    class="mt-2"
                    :multiple="false"
                    v-model="AttachmentFiles"
                    @input-file="inputFile"
                    @change="UploadFiles"
                    ref="AttachmentUploader">
                    <button type="button" class="button rounded-lg bg-theme-1 text-white">{{ files && files.length ? '修改檔案' : '上傳檔案'}}</button>
                </file-upload>
              </div>
              <div class="table w-full p-2">
                <table class="w-full border table">
                    <thead>
                        <tr class="bg-gray-50 border-b">
                            <th class="p-2 border-r  font-thin">
                                <div class="flex items-center justify-center">名稱</div>
                            </th>
                            <th class="p-2 border-r  font-thin">
                                <div class="flex items-center justify-center">功能</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center border-b " v-for="(item, index) in files" :key="index">
                            <td class="p-2 border-r">{{ item.Name }}</td>
                            <td>
                                <!-- <a :href="item.Uri" target="_blank" class="bg-blue-500 p-2 text-white rounded-lg hover:shadow-lg  mr-3" >下載</a> -->
                                <button type="button" class="button bg-blue-500 p-2 text-white hover:shadow-lg" @click="download(item.Id)">下載</button>
                                <!-- <button type="button" class="bg-red-500 p-2 text-white rounded-lg hover:shadow-lg " @click="deleteFile(item.Id)">刪除</button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
  .swiper-container {
    padding-top: 5px;
    padding-bottom: 30px;
  }
</style>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted } from '@cloudfun/core'
import FileUpload from 'vue-upload-component';

export default defineComponent({
  components: {
    FileUpload
  },
  setup () {
    const AttachmentUploader = ref({});
    const AttachmentFiles = ref([]);
    const uploader = ref({});
    const files = ref<any[]>();
    const model = CloudFun.current?.model;
    const data = ref<any>();

    const loadData = () => {
      return model?.dispatch("files/find", 2);
    };

    const init = async() => {
      const tempData = await loadData();
        console.log('temp', tempData);
        if(tempData) {          
          files.value = [tempData]
        }
    };

    onMounted(async() => {
      try {
        init();
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }
    });

    const UploadFiles = () => {
      var obj = AttachmentFiles.value;
      const formData = new FormData();
      if(obj.length > 0){
        var file = obj[0]['file'];
        var limit = 1024 * 1024 * 25;
        if(file['size'] >= limit) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '檔案大小不得超過25M' })
          return;
        }
        console.log(file['name'])
        if (!/\.(gif|jpg|jpeg|png|webp|zip|rar|doc|docx|ppt|pptx|xlsx|xls|csv|txt|pdf)$/i.test(file['name'])) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file);
      }
      
      model?.dispatch(`files/insert`, { formData: formData, mode: 'other', fileType: 2 }).then(
        () => {          
          CloudFun.send('info', { subject: '執行成功', content: '上傳完成' })
          init();
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => {
      });
    }

    const download = (id: number) => {
        window.open(`${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`)
        // model?.dispatch(`files/download`, id).then(
        //   (data) => { console.log(data) },
        //   failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
        // );
    }

    return {
      data,
      uploader,
      files,
      AttachmentUploader,
      AttachmentFiles,
      UploadFiles,
      download,
    }
  },
  methods: {
    onGridRemoveSelectedRows (rows: any[], callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    }
  }
})
</script>
