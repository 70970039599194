
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted } from '@cloudfun/core'
import FileUpload from 'vue-upload-component';

export default defineComponent({
  components: {
    FileUpload
  },
  setup () {
    const AttachmentUploader = ref({});
    const AttachmentFiles = ref([]);
    const uploader = ref({});
    const files = ref<any[]>();
    const model = CloudFun.current?.model;
    const data = ref<any>();

    const loadData = () => {
      return model?.dispatch("files/find", 2);
    };

    const init = async() => {
      const tempData = await loadData();
        console.log('temp', tempData);
        if(tempData) {          
          files.value = [tempData]
        }
    };

    onMounted(async() => {
      try {
        init();
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }
    });

    const UploadFiles = () => {
      var obj = AttachmentFiles.value;
      const formData = new FormData();
      if(obj.length > 0){
        var file = obj[0]['file'];
        var limit = 1024 * 1024 * 25;
        if(file['size'] >= limit) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '檔案大小不得超過25M' })
          return;
        }
        console.log(file['name'])
        if (!/\.(gif|jpg|jpeg|png|webp|zip|rar|doc|docx|ppt|pptx|xlsx|xls|csv|txt|pdf)$/i.test(file['name'])) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file);
      }
      
      model?.dispatch(`files/insert`, { formData: formData, mode: 'other', fileType: 2 }).then(
        () => {          
          CloudFun.send('info', { subject: '執行成功', content: '上傳完成' })
          init();
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => {
      });
    }

    const download = (id: number) => {
        window.open(`${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`)
        // model?.dispatch(`files/download`, id).then(
        //   (data) => { console.log(data) },
        //   failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
        // );
    }

    return {
      data,
      uploader,
      files,
      AttachmentUploader,
      AttachmentFiles,
      UploadFiles,
      download,
    }
  },
  methods: {
    onGridRemoveSelectedRows (rows: any[], callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    }
  }
})
